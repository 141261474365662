import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';
import Markdown from 'markdown-to-jsx';
import Layout from '../components/layout';
import Testimonials from '../components/Testimonials';

import useObserver from '../hooks/useObserver';

const About = ({ data, location }) => {
  const {
    bio,
    primary_image,
    secondary_image,
    testimonials,
  } = data.markdownRemark.frontmatter;

  const settings = { rootMargin: '0px 0px -100px 0px' };
  const { ref: bioRef, hasEntered: bioHasEntered } = useObserver(settings);
  const { ref: imageRef, hasEntered: imageHasEntered } = useObserver(settings);

  return (
    <Layout location={location}>
      <SEO title="About" />
      <TopSection
        ref={bioRef}
        className={!bioHasEntered ? 'fade-in' : undefined}
      >
        <Text>
          <Markdown>{bio}</Markdown>
        </Text>
        <img src={primary_image} alt="" />
      </TopSection>

      <SecondaryImage
        ref={imageRef}
        className={!imageHasEntered ? 'fade-in' : undefined}
      >
        <img src={secondary_image} alt="" />
      </SecondaryImage>
      <Testimonials testimonials={testimonials} />
    </Layout>
  );
};

export default About;

const TopSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 10rem 0 5rem;
  transition: transform 1s, opacity 1s;

  @media (min-width: 800px) {
    padding: 10rem 6vw 5rem 8vw;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media (min-width: 1200px) {
    padding: 10rem 10vw 5rem 12vw;
  }

  img {
    width: 70vw;
    align-self: flex-end;

    @media (min-width: 800px) {
      max-height: none;
      width: 40vw;
    }
  }
`;

const Text = styled.div`
  padding: 0 2rem 5rem 1.5rem;
  max-width: 38rem;

  @media (min-width: 800px) {
    padding: 0;
    max-width: 24rem;
  }
  @media (min-width: 1000px) {
    max-width: 28rem;
  }
`;

const SecondaryImage = styled.section`
  padding: 5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 1s, opacity 1s;

  @media (min-width: 800px) {
    padding: 5rem;
  }

  img {
    display: block;
    width: 100%;
    margin: 0 0 1.5rem;
    transition: transform 1s, opacity 1s;

    @media (min-width: 800px) {
      width: 80vw;
      max-width: 1200px;
    }

    @media (min-width: 1000px) {
      width: 70vw;
    }
  }
`;

export const query = graphql`
  {
    markdownRemark(frontmatter: { title: { eq: "about" } }) {
      frontmatter {
        bio
        primary_image
        secondary_image
        testimonials {
          name
          rating
          review
        }
      }
    }
  }
`;
