import React, { useState } from 'react';
import styled from 'styled-components';
import { H2, P } from '../styles/Typography';
import { motion, AnimatePresence } from 'framer-motion';

import useObserver from '../hooks/useObserver';

const variants = {
  enter: () => ({
    y: 50,
    opacity: 0,
    position: 'absolute',
  }),
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1,
    position: 'static',
  },
  exit: () => ({
    zIndex: 0,
    y: -50,
    opacity: 0,
    position: 'absolute',
  }),
};

const Testimonials = ({ testimonials }) => {
  const [slide, setSlide] = useState(0);
  const { rating, review, name } = testimonials[slide];

  const { ref, hasEntered } = useObserver({ rootMargin: '0px 0px -100px 0px' });

  return (
    <Container ref={ref} className={!hasEntered ? 'fade-in' : undefined}>
      <AnimatePresence initial={false}>
        <Slide
          key={slide}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            opacity: { duration: 0.2 },
            y: { type: 'spring', stiffness: 300, damping: 300 },
          }}
        >
          <Stars>
            {Array(parseInt(rating))
              .fill()
              .map((_, i) => (
                <svg
                  key={i}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#E2B3AA"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 0L15.174 7.63131L23.4127 8.2918L17.1357 13.6687L19.0534 21.7082L12 17.4L4.94658 21.7082L6.8643 13.6687L0.587322 8.2918L8.82596 7.63131L12 0Z" />
                </svg>
              ))}
          </Stars>
          <H2>{review}</H2>
          <P>{name}</P>
        </Slide>
      </AnimatePresence>
      <Dots>
        {testimonials.map(({ name }, i) => (
          <Dot key={name} active={slide === i} onClick={() => setSlide(i)} />
        ))}
      </Dots>
    </Container>
  );
};

export default Testimonials;

const Container = styled.div`
  max-width: 50rem;
  margin: 0 auto;
  transition: transform 1s, opacity 1s;
  /* height: 50vh; */
`;
const Slide = styled(motion.div)`
  outline: none;
  max-width: 50rem;
  text-align: center;
  position: absolute;
  /* height: 50vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 2rem;
`;
const Dots = styled.div`
  /* margin-top: 3rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Dot = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  z-index: 100;
  background: ${({ active }) => (active ? '#6e514c' : '#ecd9d4')};

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Stars = styled.div`
  margin: 0 0 1rem;

  svg {
    margin-right: 0.25rem;

    &:last-child {
      margin: 0;
    }
  }
`;
